import React, { useState, useEffect, useRef } from 'react';
import '../styles/globalStyles.css';

const FauxCursor = () => {
  const cursorRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const showCursor = () => setIsVisible(true);
    const hideCursor = () => setIsVisible(false);
  
    const hideCursorAreas = document.querySelectorAll('.hook, .topBar');
    hideCursorAreas.forEach(element => {
        element.addEventListener('mouseenter', hideCursor);
        element.addEventListener('mouseleave', showCursor);
    });
  
    return () => {
        hideCursorAreas.forEach(area => {
            area.removeEventListener('mouseenter', hideCursor);
            area.removeEventListener('mouseleave', showCursor);
        });
    };
  }, []);

  useEffect(() => {
    const moveCursor = (e) => {
      // Only move the cursor if it's set to be visible
      if (isVisible) {
        const { clientX: x, clientY: y } = e;
        cursorRef.current.style.left = `${x}px`;
        cursorRef.current.style.top = `${y}px`;
      }
    };

    document.addEventListener('mousemove', moveCursor);

    return () => {
      document.removeEventListener('mousemove', moveCursor);
    };
  }, [isVisible]);

  return (
    <div
      ref={cursorRef}
      style={{
        display: isVisible ? 'block' : 'none', // Control visibility here
        position: 'fixed',
        left: 0,
        top: 0,
        width: '30px',
        height: '30px',
        backgroundColor: 'rgba(255, 0, 0, 0.8)',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        zIndex: 9999,
      }}
    />
  );
};

export default FauxCursor;
