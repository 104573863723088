const powerWords = [
    "engaging",
    "beautiful",
    "innovative",
    "scalable",
    "creative",
    "effective",
    "dynamic",
    "powerful",
    "versatile",
    "intuitive",
    "robust",
    "flexible",
    "efficient",
    "viral",
    "reliable",
    "elegant",
    "exceptional",
    "disruptive",
  ];  

export default powerWords;