import React from 'react';
import '../styles/globalStyles.css';
import '../styles/topBarStyles.css';

const TopBar = () => {
  return (
    <div className="topBar">
        <div className="nameContainer">altasyn</div>
        <div className="linksContainer">
          {/*
            <a href="/portfolio" style={{ margin: '0 15px', textDecoration: 'none', color: 'white' }}>Portfolio</a>
            <a href="/contact" style={{ margin: '0 15px', textDecoration: 'none', color: 'white' }}>Contact</a>
            <a href="/login" style={{ margin: '0 15px', textDecoration: 'none', color: 'white' }}>Login</a>
          */}
        </div>
    </div>
  );
};

export default TopBar;
