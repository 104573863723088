import React, { useState, useEffect } from 'react';
import '../styles/globalStyles.css';
import '../styles/homePageStyles.css';
import powerWords from '../constants/language';
import TopBar from '../components/topBar';
import FauxCursor from '../components/fauxCursor';

const HomePage = () => {
  const numParticles = 50;
  const [particles, setParticles] = useState([]);


  useEffect(() => {
    const initialParticles = Array.from({ length: numParticles }, () => {
      const vx = (Math.random() - 0.5) * 2;
      const vy = (Math.random() - 0.5) * 2;
      //const size = (Math.random() - 0.5) * 200;
      return {
          size: 30,
          x: Math.random() * window.innerWidth - 30,
          y: Math.random() * window.innerHeight - 30,
          vx,
          vy,
      };
  });
    setParticles(initialParticles);
  }, [numParticles]);

  useEffect(() => {
    const interval = setInterval(() => {
      setParticles(particles => particles.map(p => {
        let x = p.x + p.vx;
        let y = p.y + p.vy;

        // Keep particles within the window bounds
        if (x <= 0 || x >= window.innerWidth - p.size) p.vx *= -1;
        if (y <= 20 || y >= window.innerHeight - p.size) p.vy *= -1;

        return { ...p, x: p.x + p.vx, y: p.y + p.vy };
      }));
    }, 20);

    return () => clearInterval(interval);
  }, []);


  // Move particles away from the cursor
  const handleMouseMove = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    setParticles(particles.map(p => {
      const angle = Math.atan2(p.y - mouseY, p.x - mouseX);
      const distance = Math.sqrt(Math.pow(p.x - mouseX, 2) + Math.pow(p.y - mouseY, 2));
      const reactionDistance = 50;

      if (distance < reactionDistance) {
        // Apply a simple formula to increase speed based on how close the cursor is
        const speed = (1 - distance / reactionDistance);
        p.vx = p.vx + Math.cos(angle) * speed;
        p.vy = p.vy + Math.sin(angle) * speed;
      }

      return p;
    }));
  };

  return (
    <div className="homepage" onMouseMove={handleMouseMove}>
      <TopBar />
      <FauxCursor />
      <div className="particlesContainer" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
        {particles.map((particle, index) => (
          <div key={index} style={{
            position: 'absolute',
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            backgroundColor: 'rgba(100, 100, 100, 0.5)',
            borderRadius: '50%',
            transform: `translate(${particle.x}px, ${particle.y}px)`,
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          }} />
        ))}
      </div>
      <div className="content" style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 2 }}>
        <span className="hook">We build
          <div className="adjective-container">
            <div className="adjectives">
              {powerWords.map((word, index) => (
                <React.Fragment key={index}>
                  <span>{word}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
          products.
        </span>
      </div>
    </div>
  );
};

export default HomePage;
